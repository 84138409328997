// The MMWR week is the week of the epidemiologic year for which the
// National Notifiable Diseases Surveillance System (NNDSS) disease report is
// assigned for the purposes of MMWR disease incidence reporting and publishing.
// When a notifiable disease case is reported to the state or local health
// department, it is assigned (coded) to an MMWR Week. (1)
//
// MMWR Week does not fall on the same dates every year. MMWR weeks last
// from Sunday through Saturday and usually range from 1 to 52. Depending
// on how the days of the week fall across years, it is possible to occasionally
// have a Week 53. Week #1 of an MMWR year is the first week of the year that has
// at least four days in the calendar year.
export const MMWR_2026 = [
  // I'm updating this to support the current logic but we definetly should replace all of
  // the hardcoded MMWR week logic and use an npm package like epi-week
  // https://www.npmjs.com/package/epi-week/v/0.0.1
  "1/10/2026",
  "1/17/2026",
  "1/24/2026",
  "1/31/2026",
  "2/7/2026",
  "2/14/2026",
  "2/21/2026",
  "2/28/2026",
  "3/7/2026",
  "3/15/2026",
  "3/22/2026",
  "3/29/2026",
  "4/5/2026",
  "4/12/2026",
  "4/19/2026",
  "4/26/2026",
  "5/3/2026",
  "5/10/2026",
  "5/17/2026",
  "5/24/2026",
  "5/31/2026",
  "6/7/2026",
  "6/14/2026",
  "6/21/2026",
  "6/28/2026",
  "7/5/2026",
  "7/12/2026",
  "7/19/2026",
  "7/26/2026",
  "8/2/2026",
  "8/9/2026",
  "8/16/2026",
  "8/23/2026",
  "8/30/2026",
  "9/6/2026",
  "9/13/2026",
  "9/20/2026",
  "9/27/2026",
  "10/4/2026",
  "10/11/2026",
  "10/18/2026",
  "10/25/2026",
  "11/1/2026",
  "11/8/2026",
  "11/15/2026",
  "11/22/2026",
  "11/29/2026",
  "12/6/2026",
  "12/13/2026",
  "12/20/2026",
  "12/27/2026",
  "1/3/2027"
];
